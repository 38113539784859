<template>
    <div class="h-screen items-center flex justify-center">
        <div class="flex flex-col justify-center">
            <div
            class="border-gray-400 border-2 border-separate px-3 my-5 pt-1.5 pb-3 mx-auto input-userdata"
            >
                <h3 class="mb-1.5 mt-1 text-lg">Byta lösenord</h3>
                <div class="flex text-green-600 justify-center">
                    <i class="w-64">{{ this.passChanged }}</i>
                </div>
                <table>
                    <tr>
                    <td align="right" class="py-2.5">
                        <div class="pb-3">
                        <label for="presentPassword">Nuvarande lösenord:</label>
                        </div>
                    </td>
                    <td align="left">
                        <div class="pb-3">
                        <input
                            v-model="password"
                            :class="{ 'border-red-400': badPassword }"
                            class="w-24"
                            type="password"
                        />
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <td align="right" class="py-1">
                        <label for="password">Nytt lösenord:</label>
                    </td>
                    <td align="left">
                        <input
                        v-model="newPassword"
                        :class="{
                            'border-red-400': badNewPass,
                        }"
                        class="w-24"
                        type="password"
                        />
                    </td>
                    </tr>

                    <tr>
                    <td align="right" class="py-2.5">
                        <label for="repeatPassword">Upprepa lösenord:</label>
                    </td>
                    <td align="left">
                        <input
                        v-model="repeatPassword"
                        :class="{ 'border-red-400': badRepeatPass }"
                        class="w-24"
                        type="password"
                        />
                    </td>
                    </tr>
                </table>

                <div class="flex justify-center">
                    <i class="w-64 text-red-700">{{ this.errString }}</i>
                </div>
                <div class="flex justify-center">
                    <button @click="changePassword" class="btn-black mt-2" type="submit">
                    Byt lösenord
                    </button>
                </div>
            </div>
            <p @click="goBack" class="underline italic cursor-pointer">Gå tillbaka till Familjesida</p>
        </div>
    </div>
</template>

<script>
import apiCalls from '../services/apiCalls.js'
import Swal from 'sweetalert2'

export default {
    name: "Settings",
    data() {
      return {
        secret: false,
        password: "",
        newPassword: "",
        repeatPassword: "",
        badPassword: false,
        badNewPass: false,
        badRepeatPass: false,
        errors: [],
        passChanged: "",
      }
    },
    computed: {
        errString() {
            let allErr = ""
            this.errors.forEach(text => {
                allErr = allErr + text + " "
            })
            return allErr
        }
    },
    methods: {
        changePassword() {
            const { password, newPassword, repeatPassword } = this

            this.errors = []
            this.badPassword = this.badNewPass = this.badRepeatPass = false
            let getOut = false
            // let fieldEmpty = false

            if (!password.length) {
                this.badPassword = true
                // fieldEmpty = true
                getOut = true
            }

            if (!newPassword.length) {
                this.badNewPass = true
                // fieldEmpty = true
                getOut = true
            }
            console.log("First poinmt of interest")
            if (!repeatPassword.length) {
                this.badRepeatPass = true
                // fieldEmpty = true
                getOut = true
            }

            if (getOut) {
                this.errors.push("Alla fält är inte ifyllda")
                return
            }

            if (newPassword.trim().length < 7) {
                this.errors.push("Det nya lösenordet måste vara minst 7 tecken långt")
                this.badNewPass = true
                return
            }

            console.log("Now I'm here")
            if (newPassword === repeatPassword) {
                console.log("Calling changePassword with pswd --- " + password + " --- and new Pass: --- " + newPassword)
                apiCalls.changePassword(Swal, password, newPassword).then(
                (resp) => {
                    console.log(resp)
                    console.log("Vi bytte lösenord")
                    console.log(resp)
                    console.log("Ovan är svaret")
                    this.passChanged = "Du har bytt lösenord"
                    this.password = this.newPassword = this.repeatPassword = ""
                },
                (err) => {
                    console.log("Kunde ej byta lösenord")
                    console.log(err)
                    console.log(
                    "Error message from changePassword in Settings.vue: ",
                    err.response.data.message
                    )
                    if (err.response.data.message === "badPassword") {
                        this.badPassword = true
                        this.errors.push("Felaktigt lösenord")
                    }
                })
            } else {
                this.badRepeatPass = true
                this.errors.push("Du upprepade inte lösenordet korrekt")
            }
        },
        goBack() {
            this.$router.push('/') 
        }
    }
}
</script>
<style scoped>
input {
    border: 1px solid lightgray;
    border-radius: 5px;

}
button {
    background-color: black;
    border: 2px solid darkgrey;
    border-radius: 5px;
    color: white;
    padding: 0 0.5em;
    font-weight: bold;
}
</style>
