<template>
  <div class="flex justify-between min-h-screen">
    <div class="flex-shrink-0 w-56 bg-black">
      <div class="pt-12">
        <p><span
          class="text-white text-xl cursor-pointer"
          @click="activateAllCat"
          :class="{ active: allCategories }"
        >Alla kategorier</span></p>
        <p v-for="cat in categoriesSorted" 
          :key="cat.id" 
          class="text-white text-xl mt-1"
          @click="activateThisCat(cat.id)"
        >
          <span 
            :class="{ active: cat.id === activeCatId }"
            class="cursor-pointer"
            >{{cat.text}}</span>
        </p>
      </div>
    </div>
    <div class="article flex-shrink flex-grow mx-20">
      
      <EditArticle @toggle-article="toggleArticle" @get-all="getAll" @reset-article="resetArticle" v-model="articleObject" :options="categoriesSorted" 
        :editMode="editMode" :removed="removed" :newArticle="newArticle" :editArticle="editArticle && !removed" :autoTags="autoTags" :userLevel="userLevel"></EditArticle>

      <div v-if="!newArticle">
        <ListArticle
          @editStory="editStory"
          v-for="article in filteredArticles"
          :article="article"
          :key="article.id"
          :editMode="editMode"
          :removed="removed"
          :editToggle="editToggle"
          :editId="editId"
          :userLevel="userLevel"
          @getAll="getAll"
        >
        </ListArticle>
      </div>
    </div>
    <!-- <div class="flex-shrink-0 w-56 bg-black"></div> -->
    <div class="top-0 bottom-0 right-0 w-56 bg-black text-white">
      <div class="flex justify-between items-center bg-gray-700 px-3 py-1">
        <div class="text-left inline-block mr-1.5">
            <p class="inline-block mr-1.5 text-xs">Redigera</p>
            <Toggle v-model="editMode" />
        </div>
        <p @click="logOut" class="cursor-pointer inline-block ml-3 px-0.5 bg-gray-200 border rounded border-gray-200 text-black text-xs border border-white">Logga ut</p>
      </div>
      <div :class="{ 'justify-between': this.userLevel === 'admin' }" class="flex justify-end w-full bg-gray-700">
        <div v-if="editMode && this.userLevel === 'admin'" class="bg-gray-700 px-3 py-1"><p @click="this.$router.push('/createuser') " class="cursor-pointer inline-block ml-3 px-0.5 bg-gray-200 border rounded border-gray-200 text-black text-xs border border-white">Skapa användare</p></div>
        <div v-if="editMode" class="bg-gray-700 px-3 py-1"><p @click="changePassword" class="cursor-pointer inline-block ml-3 px-0.5 bg-gray-200 border rounded border-gray-200 text-black text-xs border border-white">Byta lösenord</p></div>
      </div>
        <ListTags
        :tags="storyTags"
        @select-update="selectUpdate"
      ></ListTags>
    </div>
    
  </div>
 

</template>

<script>
import ListArticle from '../components/ListArticle.vue'
import EditArticle from '../components/EditArticle.vue'
import ListTags from '../components/ListTags.vue'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import Swal from 'sweetalert2'
import apiCalls from '../services/apiCalls.js'
import Toggle from '@vueform/toggle'
import { store } from '../store.js'


export default {
  data() {
    return {
      userLevel: null,
      editMode: false,
      newArticle: false,
      editArticle: false,
      editToggle: true,
      editId: null,
      testTag: "",
      testTags: ["aa","bb"],
      testAutoTags: ["qqq", "rrr"],
      categories: [],
      allCategories: true,
      activeCatId: null,
      storyTags: [],
      selectedTags: [],
      articleObject: this.defaultArticleObject
      ,
      defaultArticleObject: 
      {
        heading: "",
        story: "",
        year: "",
        category: "",
        tag: "",
        tags: [],
        id: null,
        removed: null
      },
      articles: [
      ],
      tagLinks: [
        {"id":1, "articleId": 2, "tagId": 1}, {"id":2, "articleId": 8, "tagId": 1}, {"id":3, "articleId": 9, "tagId": 2}, {"id":4, "articleId": 2, "tagId": 2}
      ],

      films:[
        ["A Throne Too Far", 0],
        ["The Cat Wasn't Invited", 1],
        ["You Only Meow Once", 2],
        ["Catless in Seattle", 3]
      ],
    }
  },
  computed: {
    taggedArticles: function () {
      console.log("computing taggedArticles")
      console.log("this.storyTags", this.storyTags)
      let tagged = this.articles.map((article)=> {
        let articleTags = []
        let tagMatches = 0
        this.tagLinks.map(tLink => {
          console.log("mapping tagLinks" + tLink)
          if (tLink.articleId === article.id) {
            tagMatches+=1
            console.log("tagMatches", tagMatches)
            console.log("YES pushing tag" + tLink.tagId)
            console.log("tLink", tLink)
            let tagObject = this.storyTags.find(thisTag => tLink.tagId === thisTag.id)
            if (tagObject) {            
              console.log("tagObject:")
              console.dir(tagObject)
              console.log("tagObject.text")
              console.dir(tagObject.text)
              let tagText = tagObject.text
              articleTags.push({id: tLink.tagId,text: tagText} )
            }
          }
        })
        articleTags.sort((a,b)=>a-b)
        article.tags = articleTags
        let tagTexts = []


        const svCollator = new Intl.Collator('sv')

        tagTexts.sort((a, b) => svCollator.compare(a,b))

        article.tagTexts = tagTexts
      
        return article
      })

      tagged = tagged.filter(x => x.id !== this.editId)
      // tagged.sort((a,b) => Date.parse(a.updated) - Date.parse(b.updated))
      tagged.sort((a,b) => {
          let aVal = a.updated || a.created
          let bVal = b.updated || b.created

          return Date.parse(bVal) - Date.parse(aVal)
        })

      return tagged
    },

    filteredArticles() {
      let rightCategory = []

      if (this.allCategories) {
          console.log("ALL CATEGORIES") 
          rightCategory = [...this.taggedArticles]
        }
      else {
          let activeCat = this.categoriesSorted.find(cat => cat.id === this.activeCatId)
          console.log("activeCatObj", activeCat )
          rightCategory = this.taggedArticles.filter(article => article.category === activeCat.text)
          console.log("rightCategory", rightCategory)
      }
      
      if (this.removed){
        console.log("REMOVED ARTICLES")
        rightCategory = this.taggedArticles.filter(article => article.removed !==null)
      }

      if (this.selectedTags.length < 1) {
        console.log("NO TAGS")
        return rightCategory
      }
      console.log("CAT ID from filtered", this.activeCatId)
  
      console.log("RT FROM FILTER", rightCategory)
      let filtered = rightCategory.filter(article => {
        let hits = article.tags.filter(tag => {
          let matches = this.selectedTags.filter(selected => {
            console.log("selected.id", selected.id)
            console.log("tag in filtered id", tag.id)
            return selected.id === tag.id
          })
          
          console.log("matches", matches)

          if (matches.length > 0) {
            console.log("We have a match!!!", tag)
              return true
            } else {
              return false
            }
        })

        if (hits.length) {
          return true
        } else {
          return false
        }
      })
      console.log("updated")
      console.log("updated val", filtered[0].updated)
      console.log("updated code", Date.parse(filtered[0].updated))

      
       
   
 
   

      return filtered
    },
    removedArticles() {
      let removed = this.taggedArticles.filter( article => article.removed)
      return removed
    },

    categoriesSorted: function() {
      let lastElements = []
      let newArr = []

      let array = this.categories

      for (const i in array) {
        if (array[i].text.toLowerCase() === "övrigt") {
          lastElements.push(array[i])
        } else {
          newArr.push(array[i])
        }
      }

      for (const i in lastElements) {
        newArr.push(lastElements[i])
      }

      console.log("this.userLevel", this.userLevel)
      console.log("this.editMode", this.editMode)
      if (this.userLevel === "admin" && this.editMode) {
        newArr.push({"id":999999, "text":"Borttagna artiklar"})
      }

      return newArr
    },
    
    totalContent: function() {
      return "<h1>" +this.articleHeading + "</h1>"+ this.editorContent
    },

    autoTags: function() {
      let tagTextsAll = this.storyTags.map(tag => tag.text)

      if (this.testTag.value === '') {
        return []
      }

      let autoTags = tagTextsAll.map(x => {return {"text": x}})

      let matches = 0

      if (tagTextsAll.length < 1) {
        return []
      } else {
       console.log("tagTextsAll", tagTextsAll) 
      }

      return autoTags.filter(myTag => {
        console.log("myTag", myTag)
        console.log("testTags", this.testTags)
        console.log("this.articleObject", this.articleObject)
        if (
          myTag.text.toLowerCase().includes(this.articleObject.tag.toLowerCase())
          && matches < 10
        ) {
          matches++
          return myTag
        }
      })
    },
    removed () {
      if (this.activeCatId === 999999) {
        return true
      }
      return false
    }


    // contentSize: function() {
    //   return new Blob([this.editorContent]).size
    // }
  },

  testAutoTags: function() {
    return this.testAutoTags
  },

  watch: {
    content() {
      console.log("Content changed")
    },
    editMode(val) {
      console.log("Val ", val)
      if (!val) {
        this.newArticle = false
        this.editArticle = false
        this.editId = null
        if (this.removed) {
          this.allCategories = true
          this.activeCatId = null
        }
      }
    },

    newArticle(val) {
      console.log("newArticle", val)
      if (!val) {
        console.log("Resetting article")
        this.resetArticle()
      }
    },
    removed(val) {
      if (val) {
        this.selectedTags = []
      }
    }
  },

  methods: {
    alertTest: function() {
      Swal.fire ({title: "Hello, BOY", timer: 3000})
      //this.$swal('Hello Vue world!!!')
    },
    toggleArticle() {
      console.log("toggleArticle")
      let isNew = this.newArticle
      let isEdit = this.editArticle
      this.resetArticle()
      if (!isNew && !isEdit) {
        this.newArticle = true
      } else if (isEdit && !isNew) {
        this.editArticle = false
      } else if(isNew) {
        this.newArticle = false
      }
      
    },
    resetArticle() {
      this.articleObject = {...this.defaultArticleObject}   
    },
    editStory(story) {
      console.log("This is from editStory", story)
      this.newArticle = false
      this.articleObject = story
      let filteredYear = story.year_event ?? ""
      this.articleObject =       
      {
        heading: story.heading,
        story: story.story,
        year: filteredYear.toString(),
        category: story.category,
        tag: "",
        tags: story.tags,
        id: story.id
      }
      console.log("articleObject", this.articleObject)
      console.log("articleObject.story", this.articleObject.story)
      this.editArticle = true
      this.editToggle = !this.editToggle
      this.editId = story.id
      window.scrollTo(0,0)
    },
    changePassword() {
      this.$router.push('changepassword') 
    },
    getArticles() {
      apiCalls.getArticles(Swal).then((resp) => {
        console.log("Resp from getArticles", resp)
        this.editId = null
        this.articles = resp.data
      })
    },
    getStoryTags(keepEmptyTags = false) {
      apiCalls.getStoryTags(Swal).then((resp) => {
        console.log("Resp from getStoryTags", resp)
        this.storyTags = resp.data.map((tag)=> {
          return {"id":tag.id, "text": tag.tag_name}
        })
        console.log("this.storyTags nu:", this.storyTags)
      })
      if (!keepEmptyTags) {
        apiCalls.removeEmptyTags(Swal).then((resp) => {
          console.log(resp)
        })
      }
    },
    getTagLinks() {
      apiCalls.getTagLinks(Swal).then((resp) => {
        console.log("Resp from getTagLinks", resp)
        this.tagLinks = resp.data.map((link)=> {
          return {"id":link.id, "tagId": link.tag_id, "articleId": link.article_id}
        })
      })
    },
    getCategories() {
      apiCalls.getCategories(Swal).then((resp) => {
        console.log("Resp from getCategories", resp)
        this.categories = resp.data.map((cat)=> {
          return {"id":cat.id, "text": cat.categoryname}
        })
      })
    },
    getUserLevel() {
      apiCalls.getUserLevel(Swal).then((resp) => {
        console.log("Resp from getUserLevel", resp)
        this.userLevel = resp.data
      })
    },
    logOut() {
      store.removeToken()
      this.$router.push("/login")
    },
    getAll(keepEmptyTags = false) {
      this.resetArticle()
      this.getArticles()
      this.getStoryTags(keepEmptyTags)
      this.getTagLinks()
      this.getUserLevel()
      this.getCategories()
      this.editId = null
      console.log("getAll is run")
    },
    activateAllCat() {
      this.allCategories = true
      this.activeCatId = null
    },
    activateThisCat(id) {
      console.log("CATID", id)
      console.log("this.activeCatId before", this.activeCatId)
      this.allCategories = false
      this.activeCatId = id
      console.log("this.activeCatId", this.activeCatId)
    },
    selectUpdate(arr) {
      this.selectedTags = arr
    }
  },

  components: {
    ListArticle,
    EditArticle,
    ListTags,
    Toggle,
  },

  created() {
    console.log("NU E JA REDO!!!")
    //this.mainEditor.setText("QUILL LOVES MÅRTEN")
    // userApi(this.$swal)
    // console.log("this", this)
    // this.$swal('Hello VOJ world!!!')
    this.getAll()
    //console.log("newArticles", newArticles)
  }
}
</script>

<style>

html, body {
  min-height: 100vh;
}

.active {
  /* text-decoration: underline; */
  border-bottom: 2px solid white;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* ::-webkit-input-placeholder {
   font-style: italic;
}
:-moz-placeholder {
   font-style: italic;  
}
::-moz-placeholder {
   font-style: italic;  
}
:-ms-input-placeholder {  
   font-style: italic; 
} */

.article {
  max-width: 40rem;
}

.ql-container {
  height: 400px;
}

img {
  display: inline-block;
}

</style>
