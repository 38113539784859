<template>

    <editor
      api-key="no-api-key"
    />
  </template>
  
  <script>
  import Editor from '@tinymce/tinymce-vue'
  
  export default {
    name: 'App',
    components: {
      'editor': Editor
    }
  }
  </script>