import {createRouter, createWebHistory,} from 'vue-router'
import Stories from './views/Stories.vue'
import SetPass from './views/SetPass.vue'
import Login from './views/Login.vue'
import Error from "./views/Error.vue"
import CreateUser from "./views/CreateUser.vue"
import ChangePassword from "./views/ChangePassword.vue"
import TestQuillComp from "./views/TestQuillComp.vue"
import TinyMce from "./views/TinyMce.vue"
import TipTap from "./views/TipTap.vue"




const routes = [
  { path: '/', component: Stories}, 
  { path: '/setpass', component: SetPass},
  { path: '/login', component: Login},
  { path: '/createuser', component: CreateUser},
  { path: '/changepassword', component: ChangePassword},
  { path: '/testquill', component: TestQuillComp},
  { path: '/tinymce', component: TinyMce},
  { path: '/tiptap', component: TipTap},
  {
    path: "/error",
    name: "Error",
    component: Error,
    props: true,
  },
]

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})


export default router
