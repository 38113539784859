<template>
  <div class="flex items-center h-screen">
    <div class="mx-auto">
      <div class="p-2 border border-2 border-black rounded">
        <p>{{ codeVal }}</p>
        <table class="mx-auto">
          <tr>
            <td class="text-right pr-2">
              Välj ett lösenord
            </td>
            <td>
              <input type="password" v-model="pass1" class="border border-gray border-2">
            </td>
          </tr>
          <tr>
            <td class="text-right pr-2">
              Upprepa ditt lösenord
            </td>
            <td class="pt-2">
              <input type="password" v-model="pass2" class="border border-gray border-2">
            </td>
          </tr>
        </table>
      </div>
      <button @click="updatePassword" class="border border-black border-2 rounded-lg mt-4 px-2">Spara lösenord</button>
    </div>
    
  </div>
</template>

<script>
import apiCalls from "@/services/apiCalls.js"

export default {
  data() {
    return {
      pass1: "",
      pass2: "",
    }
  },
  computed: {
    isEqual: function() {
      if (this.pass1 === this.pass2) {
        return true
      } else {
        return false
      }
    },
    lengthOk: function() {
      if (this.pass1.length > 7) {
        return true
      }
      return false
    },
    codeVal: function() {
      let code = this.$route.query.code ?? null
      return code
    },
    userId: function() {
      let user = this.$route.query.id ?? null
      return user
    }
  },
  methods: {
    updatePassword() {
      console.log("updating password")
      if (this.isEqual && this.lengthOk) {
        console.log("userId", this.userId)
        console.log("code", this.codeVal)
        apiCalls.setPassword(this.$swal, this.userId, this.codeVal, this.pass1)
        console.log("Password updated")
      }
    }
  }
}
</script>