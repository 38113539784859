<!-- to get the File instance instead of transforming base64 into blob by rewriting the image handler.
The advantage of this is file name would also be sent to the server and the code is obviously more straight forward.

First add in the component a hidden file input -->

<template>
  <div>
    <QuillEditor
      id="content-editor"
      ref="myEditor"
      v-model="content"
      :options="qOptions"
    />
    <input ref="imageInput" class="d-none" type="file" accept="image/*" @change="_doImageUpload">
  </div>
</template>
<!-- Then set data, computed, and methods like this -->
<script>
import { defineComponent } from 'vue'
import { QuillEditor } from '@vueup/vue-quill'
import ImageCompress from 'quill-image-compress'


export default defineComponent({
    components: {
      QuillEditor,
    },
    props: {
      showEditor: {
        Boolean
      },
      myContent: {
        String
      }
    },
    emits: ['update:myContent'],
    content: '',
    qOptions: {
    theme: 'snow'
    },
    imageUpload: {
    url: 'path/to/image/upload/endpoint'
    },
    computed: {
      editedContent: {
        get() {
          return this.myContent
        },
        set(value) {
          this.$emit('update:myContent', value)
        }
      },
    watch: {
      showEditor(val) {
        if (val) {
          this.$refs.myEditor.setContents([])
        }
      }
    },
    methods: {
      insertImage () {
      // manipulate the DOM to do a click on hidden input
        this.$refs.imageInput.click()
      },
      async _doImageUpload (event) {
      // for simplicity I only upload the first image
      const file = event.target.files[0]
      // create form data
      const fd = new FormData()
      // just add file instance to form data normally
      fd.append('image', file)
      // I use axios here, should be obvious enough
      const response = await this.$axios.post(this.imageUpload.url, fd)
      // clear input value to make selecting the same image work
      event.target.value = ''
      // get current index of the cursor
      const currentIndex = this.quillInstance.selection.lastRange.index
      // insert uploaded image url to 'image' embed (quill does this for you)
      // the embed looks like this: <img src="{url}" />
      this.quillInstance.insertEmbed(currentIndex, 'image', response.data.url)
      // set cursor position to after the image
      this.quillInstance.setSelection(currentIndex + 1, 0)
      }
    },
    setup: () => {
    const modules = [
      {
        name: 'imageCompress',  
        module: ImageCompress, 
        options: {
          quality: 0.9,
          maxWidth: 1200,
          maxHeight: 1200, 
        },
        toolbar: {
        container: [['image']],
        handlers: {
            image: this.insertImage
          }
        }
      }
    ]

    return { modules }
  }
}
})
</script>