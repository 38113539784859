<template>
  <div v-if="tagsSelected.length"><p class="bg-gray-500 px-2 mt-3 mb-2 mx-4 text-white text-sm border border-gray-500 rounded-lg cursor-pointer" @click="purge">Rensa val</p></div>
  <div class="flex flex-wrap items-start justify-evenly mx-2 my-2">    
    <div 
      class="border-white bg-white cursor-pointer text-black border rounded-full m-1 px-2"
      v-for="tag in tagsSelected"
      :key="tag.id"
      @click="selectTag(tag)"
    >
      {{ tag.text }}
    </div>
    <div 
      class="border-white border cursor-pointer rounded-full m-1 px-2"
      v-for="tag in notSelected"
      :key="tag.id"
      @click="selectTag(tag)"
    >
      {{ tag.text }}
    </div>
  </div>
</template>


<script>
export default {

  data() {
    return {
      tagsSelected: []
    }
  },

  name: 'ListTags',

  emits: ["selectUpdate"],

  props: {
    tags: {
      type: Array
    },
  },

  methods: {
    selectTag(tag) {
      console.log(tag)

      let selected = this.tagsSelected

      let existing = selected.filter(t => t.id === tag.id)

      if (existing.length < 1) {
        selected.push(tag)
      } else {
        console.log("NONONO")
        selected = selected.filter(el => 
          {
            console.log("el.id", el.id)
            console.log("tag.id", tag.id)
            return el.id !== tag.id
          }  
        )
        console.log("selected", selected)
      }

      this.tagsSelected = selected
      this.$emit("selectUpdate", selected)
    },
    purge() {
      this.tagsSelected = []
      this.$emit("selectUpdate", this.tagsSelected)
    }
  },
  watch: {
    tags(currentTags) {
      this.tagsSelected = this.tagsSelected.filter(tag=> currentTags.includes(tag))
      this.$emit("selectUpdate", this.tagsSelected)
    }
  },
  computed: {
    notSelected() {
      console.log("this.tagsSelected from notSelected", this.tagsSelected)
      let notSelected = this.tags.filter( el => {
        let hits = this.tagsSelected.filter( thisEl => thisEl.id === el.id)
        console.log("hits", hits)
        if (hits.length < 1) {
          console.log("no hits")
          return true
        } else {
          return false
        }
      })

      return notSelected
    }
  }
}

</script>
