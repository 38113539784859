<template>
    <div id="app">
      <tiptap />
    </div>
  </template>
  
  <script>
    import Tiptap from '../components/TipTap.vue'
  
    export default {
      name: 'App',
      components: {
        Tiptap,
      },
    }
  </script>