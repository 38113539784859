<template>
  <div>
    <form class="login text-center" @submit.prevent="register">
      <h1>Skapa ny användare</h1>
      <h3 class="italic">{{ creation }}</h3>
      <table class="mx-auto input-userdata mt-3">
        <tr>
          <td align="right" class="py-2.5">
            <label for="username">Förnamn:</label>
          </td>
          <td align="left">
            <input
              required
              v-model="firstname"
              type="text"
              class="border rounded border-gray-400"
            /><span class="pl-1">*</span>
          </td>
        </tr>
        <tr>
          <td align="right" class="py-2.5">
            <label for="username">Efternamn:</label>
          </td>
          <td align="left">
            <input
              required
              v-model="lastname"
              type="text"
              class="border rounded border-gray-400"
            /><span class="pl-1">*</span>
          </td>
        </tr>
        <tr>
          <td align="right" class="py-2.5">
            <label for="username">Användarnamn:</label>
          </td>
          <td align="left">
            <input
              required
              v-model="username"
              type="text"
              :class="{ 'border-red-400': badUsername }"
              class="border rounded border-gray-400"
            /><span class="pl-1">*</span>
          </td>
        </tr>

        <tr>
          <td align="right" class="py-2.5">
            <label for="password">Lösenord:</label>
          </td>
          <td align="left">
            <input
              required
              v-model="password"
              type="password"
              autocomplete="off"
              :class="{ 'border-red-400': badPassword }"
              class="border rounded border-gray-400"
            />
            <span class="pl-1">*</span>
          </td>
        </tr>
        <tr>
          <td align="right" class="py-2.5">
            <label for="email">Email:</label>
          </td>
          <td align="left">
            <input 
              v-model="email"
              type="text"
              class="border rounded border-gray-400"
            />
            <span class="pl-1">*</span>
          </td>
        </tr>
      </table>
      <i class="text-red-700">{{ this.errString }}</i>

      <button v-if="!viewSpinner" type="submit" class="bg-gray-200 border rounded text-black border border-black mx-auto mt-2">
        Registrera
      </button>
    </form>
    <!-- <Spinner
      v-if="viewSpinner"
      size="40"
      line-size=6
      line-fg-color="#474747"
      line-bg-color="#cccccc"
      speed=1.1
      message="Vänta lite..."
      class="mt-4"
    ></Spinner> -->
  </div>
</template>

<script>
import apiCalls from '../services/apiCalls.js'
// import Spinner from "vue-simple-spinner"
import Swal from 'sweetalert2'
export default {
  name: "Register",
  data() {
    return {
      username: "",
      password: "",
      firstname: "",
      lastname: "",
      email: "",
      error: null,
      badUsername: false,
      badPassword: false,
      errString: "",
      viewSpinner: false,
      creation: "",
    }
  },

  // components: {
  //   Spinner,
  // },

  methods: {
    register() {
      const { username, password, firstname, lastname, email } = this
      console.log("THIS USERNAME", this.username)
      let errMessage = []
      let fallOut = false
      this.badUsername = false
      this.badPassword = false
      if (username.trim().length < 2) {
        errMessage.push("Ogiltigt användarnamn")
        this.badUsername = true
        fallOut = true
      }
      if (password.trim().length < 7) {
        console.log("För kort lösenord")
        errMessage.push("Lösenordet måste vara minst 7 tecken långt")
        this.badPassword = true
        fallOut = true
      }
      if (fallOut) {
        this.errString = errMessage.join(", ")
        return
      }
      this.viewSpinner = true

      console.log("firstname, lastname, username, email, password", firstname, lastname, username, email, password)
      apiCalls
        .createUser(Swal, firstname, lastname, username, email, password)
        .then(
          (response) => {
            console.log("Det gick vägen!")
            localStorage.clear()
            console.log(response)
            this.viewSpinner = false
            this.emptyFields()
            this.creation = "Ny användare skapad!"
          },
          (error) => {
            console.log("Tyvärr, funkante!")
            console.log(error)
            this.viewSpinner = false
            this.creation = "Kunde inte skapa användare"
          }
        )
    },
    emptyFields() {
      
      this.username = ""
      this.password = ""
      this.firstname = ""
      this.lastname = ""
      this.email = ""

      return 
    }
  },
}
</script>

