// store.js
import { reactive } from 'vue'

export const store = reactive({
  token: null,
  setToken(recievedToken) {
    localStorage.setItem("user-token", recievedToken)
    this.token = recievedToken
  },
  removeToken() {
    localStorage.removeItem("user-token")
    this.token = null
  }
})