<template>
  <div class="flex flex-row items-start mt-12">
    <div v-if="!expanded && showSmallImage" class="mr-12 pic-box"><img :src="smallImage" @error="imageLoadError" ></div>
    <div class="family-article" :class="{fullwidth: !showSmallImage}" v-if="this.removed || !this.removedArticle">
      <h1 class="center-heading text-xl">{{ article.heading }}</h1>
      <div v-if="!expanded " class="makesmaller" :class="{fullwidth: !showSmallImage}" v-html=textContent></div>
      <div v-if="expanded" v-html=article.story></div>
      <div class="mt-4" v-if="expanded">
        <p v-if="article.year_event" class="text-center">Tid: {{article.year_event}}</p>
        <p v-if="editedConv" class="text-center italic">Senast ändrad: {{this.editedConv}} av {{article.updated_by}}</p>
        <p v-if="createdConv" class="text-center italic" >Skapad: {{this.createdConv}} av {{article.created_by}}</p>
      </div>
      <button class="text-gray-500 italic underline" v-if="!expanded" @click="expandArticle">Mer..</button>
      <button class="text-gray-500 italic underline" v-if="expanded" @click="collapseArticle">Mindre..</button>
      <div v-if="expanded" class="flex justify-center py-2">
        <div v-for="(tag, index) in article.tags" :key="index" 
          >
          <span class="border-black bg-gray-100 border rounded-full m-1 px-2">{{ tag.text }}</span>
        </div>
      </div>
      <button v-if="editMode && !changeArticle && !removed" @click="activateEdit" class="button-dark">Redigera artikel</button>
      <button v-if="removed" @click="removePermanently" class="button-dark">Ta bort permanent</button>  
    </div>
  </div>
</template>

<script>
import apiCalls from '../services/apiCalls.js'
import Swal from 'sweetalert2'

export default {
  data() {
   return {
    changeArticle: false,
    expanded: false,
    showSmallImage: true,
    }
  },
  name: 'ListArticle',

  props: {
    articleProp: {
      type: Object
    },
    article: {
      type: Object,
      required: true,
    },
    editMode: {
      Boolean
    },
    storyEdit: {
      type: Object,
    },
    removed: {
      type: Boolean
    },
    editId: {
      type: Number
    },
    userLevel: {
      type: String
    }
  },
  emits: ['update:articleProp', 'editStory','getAll'],
  computed: {
    theModel: {
      get() {
        return this.articleProp
      },
      set(value) {
        this.$emit('update:articleProp', value)
      }
    },
    createdConv: function () {
      return this.dateString(this.article.created)
    },
    editedConv: function () {
      return this.dateString(this.article.updated)
    },
    removedArticle: function () {
      return this.dateString(this.article.removed)
    },
    textContent: function () {
      // HTML tags contain text
        let html = this.article.story
        let div = document.createElement("div");
        div.innerHTML = html;
        let text = div.textContent || div.innerText || "";
        console.log(text)

        return text
    },
    smallImage() {
      var ele = document.createElement("div");
      ele.innerHTML = this.article.story
      var image = ele.querySelector("img");
      if (!image) {
        this.unToggleImage()
        return null
      }
      let imagetext = image.outerHTML
      let edtext = imagetext.split("src=").slice(-1)
      edtext = edtext[0].split("/")
      edtext = edtext.slice(-1)[0].split('"')
      let filename = edtext[0].slice(4)
      filename = "small_" + filename
      let path = process.env.VUE_APP_APIURL + "/images/small/" + filename
      return path
    }
  },
  watch: {
    editId(val) {
      if (val === this.article.id) {
        this.changearticle = true
      } else {
        this.changeArticle = false
      }
    },
    editMode(val) {
      if (val) {
        this.changeArticle = false
      }
    },
  },
  methods: {
    activateEdit() {
      this.$emit("editStory", this.article)
      this.changeArticle=true
    },
    expandArticle() {
      this.expanded = true
    },
    collapseArticle() {
      this.expanded = false
    },
    removePermanently() {
      apiCalls.removePermanently(Swal, [this.article.id]).then(resp => {
          console.log("resp from removePermanently backend", resp)
          this.$emit('getAll')
        }
      )
    }, 
    articleDate(inTime) {
      return inTime.split("T")[0]
    },
    articleTime(inTime) {
      let convTime = new Date(inTime)
      return convTime.toLocaleTimeString('sw-SW')
    },
    dateString(inTime) {
      if(inTime) {
        return this.articleDate(inTime) + " " + this.articleTime(inTime)
      } else {
        return null
      }
    },
    imageLoadError () {
      this.showSmallImage = false
    },
    unToggleImage () {
      this.showSmallImage = false
    }
  }
}
</script>

<style>

.makesmaller {
  width: 400px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  background:#fff;
}

.makesmaller img {
  display:None;
}

.makesmaller .image-link {
  display:None;
}

.family-article {
  width: 600px;
}

.pic-box {
  width:300px;
  text-align: right;
}

.fullwidth {
  width:100% !important;
}

</style>