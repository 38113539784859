import axios from "axios"
import router from "../router"


const userApi = function (alertFun, content = "application/json") { 
  console.log("alertFun", alertFun)
  const apiConn = axios.create({
    baseURL: process.env.VUE_APP_APIURL + `/api`,
    withCredentials: false, // This is the default
    auth:
    {
      username: "msberg",
      password: "JoddilaDunk12"
    },
    headers: {
      Accept: "application/json",
      "Content-Type": content,
      "ngrok-skip-browser-warning": true
    },
    timeout: 25000,
  })

  console.log("apiConn", apiConn)
  console.log("process.env.VUE_APP_APIURL", process.env.VUE_APP_APIURL)

  apiConn.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("user-token")
      console.log("Intercepting to add user token")
      console.log("Process env", process.env.VUE_APP_APIURL)
      console.log("adding token", token)
      if (token) {
        console.log("config", config)
        config.headers.common["x-access-token"] = token
      } else {
        console.log("No valid token")
        router.push("login").catch((err) => {
          // Ignore the vuex err regarding  navigating to the page they are already on.
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            // But print any other errors to the console
            console.log(err)
          }
        })
      }
      return config
    },
    (error) => {
      console.log("Min interception gick åt fanders..", error)
      return Promise.reject(error)
    }
  )

  apiConn.interceptors.response.use(
    (res) => {
      console.log("Interceptor detected a valid response from App.vue")
      return res
    },
    (err) => {
      console.log("Err from interceptor in App.vue", { err })
      console.log("Error message", err.message)
      //console.log("Is this error name??", err.data.name)

      if (router.response && err.response.data) {
        console.log(err.response.data)
        throw err
      }
      if (
        (err.response &&
          err.response.status === 401 &&
          err.config &&
          !err.config.__isRetryRequest) ||
        (err.response &&
          // eslint-disable-next-line no-prototype-builtins
          err.response.hasOwnProperty("data") &&
          (err.response.data.name === "JsonWebTokenError" || err.response.data.name ===
            "TokenExpiredError")
          )
      ) {
        // if you ever get an unauthorized, logout the user
        router.push("/login").catch((err) => {
          // Ignore the vuex err regarding  navigating to the page they are already on.
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            // But print any other errors to the console
            console.log(err)
          }
        })
        // you can also redirect to /login if needed !
      } else if (err.message === "Network Error") {
        console.log("pushing error route")
        router
          .push({
            name: "Error",
            params: { message: "Ingen kontakt fås med servern." },
          })
          .catch((err) => {
            // Ignore the vuex err regarding  navigating to the page they are already on.
            if (
              err.name !== "NavigationDuplicated" &&
              !err.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              // But print any other errors to the console
              console.log(err)
            }
          })
      }
      throw err
    }
  )
  return apiConn
}

export default userApi
